import {useQuery} from '@tanstack/react-query'
import api from 'service/core'

export const useHome = () =>
  useQuery(
    ['home'],
    () =>
      new Promise((resolve, reject) =>
        api
          .get('pub/home')
          .then((response) => resolve(response?.data?.data))
          .catch((error) => reject(error)),
      ),
  )
