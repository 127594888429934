export const ProductCard = ({data}) => {
  return (
    <a href={`/product/${data.id}`}>
      <img src={data.images[0].photo} alt='Product Img' />
      <div className='caption-product'>
        <h2>{data?.name}</h2>
        <h6>{data?.short_note}</h6>
        <span>Lihat Detail</span>
      </div>
    </a>
  )
}
