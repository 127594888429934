import SymposiumItem from 'components/Symposium/Item'
import {useSymposiumScheduleAll} from 'hooks/query/useSymposiumSchedule'

const SymposiumSchedule = () => {
  const {data} = useSymposiumScheduleAll()

  return (
    <section class='list-jadwal-symposium'>
      <div class='container-fluid px-0'>
        <div class='row'>
          <div class='col-md-12'>
            <div class='nav-top-inside'>
              <a href='/'>
                <i class='icon-chevron_left'></i>
              </a>
              <h2>Jadwal Symposium </h2>
            </div>
            <div class='slick-banner-symposium list-symposium'>
              {data?.map((data) => (
                <SymposiumItem key={data?.id} {...data} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SymposiumSchedule
