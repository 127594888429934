import {morinagaLogo} from 'assetConstants'
import failRedeem from 'assets/img/illust-klaim-gagal.svg'
import {Link, useLocation} from 'react-router-dom'

const ErrorRedeem = () => {
  const {state} = useLocation()

  return (
    <section className='pendaftaran-berhasil'>
      <div className='container-fluid px-0'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='banner-top-daftar text-center '>
              <div className='logo-top'>
                <img src={morinagaLogo} alt='' />
              </div>
              <div className='illustration-top'>
                <img src={failRedeem} alt='' />
              </div>
            </div>
            <div className='desc-text-box'>
              <h2>Maaf, Redeem Gagal</h2>
              <p>{state}</p>
              <div className='btn-bottom-fixed'>
                <Link to='/' className='btn btn-primary-outline btn-next mt-3'>
                  Kembali ke halaman awal
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ErrorRedeem
