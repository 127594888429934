import {forwardRef, useState} from 'react'

const options = [
  {label: 'DSA', value: 'DSA'},
  {label: 'PPDS', value: 'PPDS'},
  {label: 'GP', value: 'GP'},
  {label: 'Lainnya', value: 'LAINNYA'},
]

const SpecialistOption = forwardRef(({isOpen, onChoose}, ref) => {
  const [selected, setSelected] = useState({})
  return (
    <div className={'bottom-box ' + (isOpen ? 'active' : '')} ref={ref}>
      <div className='d-flex justify-content-between'>
        <h2>Pilih Spesialisasi</h2>
        <a href='#' className='ic-close-btn'>
          <img src='img/ic-close-black.svg' alt='' />
        </a>
      </div>
      <div className='choose-option input-spesialisasi'>
        {options.map(({label, value}) => (
          <label className='form-control' key={label}>
            <input
              type='radio'
              name='radio'
              checked={value === selected.value}
              value={value}
              onChange={(e) => setSelected({label, value})}
            />
            {label}
          </label>
        ))}
      </div>
      <button
        onClick={() => onChoose(selected)}
        className='btn btn-primary border-0'
      >
        Pilih
      </button>
    </div>
  )
})

export default SpecialistOption
