import {useQuery} from '@tanstack/react-query'
import api from 'service/core'

export const useStaticPage = (slug) =>
  useQuery(
    ['page', slug],
    () =>
      new Promise((resolve, reject) =>
        api
          .get(`pub/page/${slug}`)
          .then((response) => resolve(response?.data?.data))
          .catch((error) => reject(error)),
      ),
    {
      enabled: !!slug,
    },
  )
