import {useQuery} from '@tanstack/react-query'
import api from 'service/core'

export const useProductList = () =>
  useQuery(
    ['product-lists'],
    () =>
      new Promise((resolve, reject) =>
        api
          .get('pub/product/lists?size=1000')
          .then((response) => resolve(response?.data?.data))
          .catch((error) => reject(error)),
      ),
  )

export const useProductDetail = (id) =>
  useQuery(
    ['product-detail', id],
    () =>
      new Promise((resolve, reject) =>
        api
          .get(`pub/product/${id}/detail`)
          .then((response) => resolve(response?.data?.data))
          .catch((error) => reject(error)),
      ),
    {
      enabled: !!id,
    },
  )
