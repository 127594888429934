import Modal, {ModalButton} from 'components/Modal'
import {forwardRef} from 'react'
import Slider from 'react-slick'

const AddsModal = forwardRef(({banners, addsModalId, onClose}, ref) => {
  return (
    <>
      <Modal modalId={addsModalId} className='modal-brosur'>
        <div className='modal-body w-100 h-100'>
          <button
            type='button'
            className='btn-close'
            style={{zIndex: 100, top: 10, right: 10}}
            data-bs-dismiss='modal'
            aria-label='Close'
            onClick={onClose}
          />
          <Slider infinite={false}>
            {banners?.map((banner, idx) => (
              <img
                key={idx}
                src={banner}
                className='img-fluid'
                alt=''
                style={{objectFit: 'contain', objectPosition: 'center'}}
              />
            ))}
          </Slider>
        </div>
      </Modal>
      <ModalButton ref={ref} targetModal={addsModalId} className='d-none' />
    </>
  )
})

AddsModal.displayName = 'AddsModal'
export default AddsModal
