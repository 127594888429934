import {useCategoryAll} from 'hooks/query/useCategory'
import {useProductList} from 'hooks/query/useProduct'
import {useEffect, useState} from 'react'
import {Category} from './components/Category'
import {ProductCard} from './components/ProductCard'
import SlideSection from './components/SlideSection'
import {SubCategory} from './components/SubCategory'

const Product = () => {
  const {data: categories} = useCategoryAll()
  const {data} = useProductList()
  const [category, setCategory] = useState()
  const [subCategory, setSubCategory] = useState()

  const handleProducts = () => {
    if (category !== undefined && subCategory != undefined) {
      return data?.data?.filter(
        (product) =>
          product.parent_category.id === category.id &&
          product.category.id === subCategory.id,
      )
    } else if (category !== undefined) {
      return data?.data?.filter(
        (product) => product.parent_category.id === category.id,
      )
    } else if (subCategory !== undefined) {
      return data?.data?.filter(
        (product) => product.category.id === subCategory.id,
      )
    } else {
      return data?.data
    }
  }

  const [products, setProducts] = useState()

  const [active, setActive] = useState()

  useEffect(() => {
    setProducts(handleProducts())
  }, [categories, data, category, subCategory])
  return (
    <section id='content-body'>
      <section className='product-list'>
        <div className='container-fluid px-0'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='nav-top-inside'>
                <a href='/'>
                  <i className='icon-chevron_left'></i>
                </a>
                <h2>Produk Morinaga</h2>
              </div>
              <SlideSection />
              <section className='product-morinaga'>
                <ul className='nav nav-tabs' id='TabProducts' role='tablist'>
                  {categories?.map((cat) => (
                    <Category
                      key={cat?.id}
                      cat={cat}
                      setCategory={setCategory}
                      setSubCategory={setSubCategory}
                    />
                  ))}
                </ul>
                <div className='tab-content' id='myTabContent'>
                  <div
                    className='tab-pane fade show active'
                    id='year-1'
                    role='tabpanel'
                  >
                    <ul className='tabs-nav'>
                      {category?.children?.map((child) => (
                        <SubCategory
                          key={child.id}
                          subCategory={child}
                          setSubCategory={setSubCategory}
                          handleActive={setActive}
                          active={active}
                        />
                      ))}
                    </ul>
                    <div className='tabs-stage'>
                      <div id='tab-1' style={{display: 'block'}}>
                        <div className='slick-similar-product'>
                          {products?.map((product) => (
                            <ProductCard data={product} key={product.id} />
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
    </section>
  )
}

export default Product
