import NotFoundPage from 'pages/NotFoundPage'
import Product from 'pages/Product'
import ProductDetail from 'pages/Product/[id]'
import Redeem from 'pages/Redeem'
import ErrorRedeem from 'pages/Redeem/Error'
import SuccessRedeem from 'pages/Redeem/Success'
import QrHandler from 'pages/Redirect/QrHandler'
import SalesHandler from 'pages/Redirect/SalesHandler'
import RegisterPage from 'pages/Register'
import RegisterSucess from 'pages/Register/Sucess'
import Survey from 'pages/Survey'
import SuccessSurvey from 'pages/Survey/Success'
import Questions from 'pages/Survey/questions'
import SymposiumSchedule from 'pages/SymposiumSchedule'
import SymposiumScheduleDetail from 'pages/SymposiumSchedule/[id]'
import Home from './pages/Home'
import Root from './pages/Root'
import Scan from './pages/Scan'

const routes = [
  {
    path: '',
    element: <Root />,
    errorElement: <NotFoundPage />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: 'register',
        element: <RegisterPage />,
      },
      {
        path: 'register-success',
        element: <RegisterSucess />,
      },
      {
        path: 'symposium-schedule',
        children: [
          {path: '', element: <SymposiumSchedule />},
          {path: ':id', element: <SymposiumScheduleDetail />},
        ],
      },
      {
        path: 'product',
        children: [
          {path: '', element: <Product />},
          {path: ':id', element: <ProductDetail />},
        ],
      },
      {
        path: 'survey',
        children: [
          {
            path: '',
            element: <Survey />,
          },
          {
            path: 'questions',
            element: <Questions />,
          },
          {
            path: 'success',
            element: <SuccessSurvey />,
          },
        ],
      },
      {
        path: 'redeem',
        children: [
          {
            path: '',
            element: <Redeem />,
          },
          {
            path: 'success',
            element: <SuccessRedeem />,
          },
          {
            path: 'error',
            element: <ErrorRedeem />,
          },
        ],
      },
      {
        path: ':salesId/representative',
        element: <QrHandler />,
      },
      {
        path: 'sales-handler/:action/:token',
        element: <SalesHandler />,
      },
    ],
  },
  {
    path: 'scan',
    element: <Scan />,
  },
]

export default routes
