import {useMutation, useQuery} from '@tanstack/react-query'
import useSession from 'hooks/useSession'
import useToaster from 'hooks/useToaster'
import {useNavigate} from 'react-router-dom'
import api from 'service/core'

export const useQuestionAll = () =>
  useQuery(
    ['question-all'],
    () =>
      new Promise((resolve, reject) =>
        api
          .get('pub/question/all')
          .then((response) => resolve(response?.data?.data))
          .catch((error) => reject(error)),
      ),
  )

export const useSubmitAnswer = (onSuccessCB) => {
  const toaster = useToaster()
  const navigate = useNavigate()
  return useMutation(
    (json) =>
      toaster(
        new Promise(async (resolve, reject) => {
          try {
            await api.post('/pub/question/submit/answer', json)
            resolve('')
          } catch (error) {
            reject(error?.response?.data)
          }
        }),
        'Survey berhasil disimpan',
        'Loading...',
      ),
    {
      onSuccess: () => {
        onSuccessCB()
        navigate('/survey/success')
      },
    },
  )
}

export const useRedeem = () => {
  const {session, sales_promotion_id} = useSession()
  const json = {
    participant_id: session,
    sales_promotion_id,
  }
  return useMutation(
    () =>
      new Promise(async (resolve, reject) => {
        try {
          await api.post('/pub/redeem', json)
          resolve('')
        } catch (error) {
          reject(error?.response?.data)
        }
      }),
  )
}
