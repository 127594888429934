import {morinagaLogoRed} from 'assetConstants'
import Modal, {ModalButton} from 'components/Modal'
import dayjs from 'dayjs'
import {useStaticPage} from 'hooks/query/useStaticPage'
import useSession from 'hooks/useSession'
import {useEffect, useRef} from 'react'

const TermModal = () => {
  const termModalId = 'termModalId'
  const modalBtnRef = useRef()
  const {privacyModal, setCookie} = useSession()

  const {data} = useStaticPage('kebijakan-privasi')
  useEffect(() => {
    if (modalBtnRef.current && !privacyModal) modalBtnRef.current.click()
  }, [])

  return (
    <>
      <Modal modalId='termModalId'>
        <div className='modal-body'>
          <div>
            <img width={70} height={50} src={morinagaLogoRed} alt='morinaga' />
          </div>
          <h2>{data?.title}</h2>
          <div className='overflow-terms'>
            <section
              dangerouslySetInnerHTML={{__html: data?.content}}
            ></section>
          </div>
        </div>
        <div className='modal-footer justify-content-center border-0'>
          <button
            type='button'
            className='btn btn-primary'
            data-bs-dismiss='modal'
            onClick={() => {
              setCookie('privacyModal', true, {
                expires: dayjs().add(1, 'month').toDate(),
              })
            }}
          >
            Saya paham, dan Setuju
          </button>
        </div>
      </Modal>
      <ModalButton
        ref={modalBtnRef}
        targetModal={termModalId}
        className='d-none'
      />
    </>
  )
}

export default TermModal
