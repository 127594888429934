import {yupResolver} from '@hookform/resolvers/yup'
import grayBack from 'assets/img/ic-back-grey.svg'
import clsx from 'clsx'
import {useQuestionAll, useSubmitAnswer} from 'hooks/query/useSurvey'
import useSession from 'hooks/useSession'
import {Fragment, useRef, useState} from 'react'
import {useForm} from 'react-hook-form'
import * as Yup from 'yup'
import AddsModal from './components/AddsModal'
import ConformModal from './components/ConformModal'
import StepWizard from './components/StepWizard'

const validation = Yup.object().shape({
  participant_id: Yup.string().required(),
  answers: Yup.array(
    Yup.object({
      answer_id: Yup.string().required('Silahkan pilih salah satu jawaban'),
      question_id: Yup.string().required(),
    }),
  ).nullable(),
})

const conformModalId = 'conformModalId'
const addsModalId = 'addsModalId'

const Questions = () => {
  const conformModalRef = useRef()
  const formRef = useRef()
  const addsModalRef = useRef()

  const {session} = useSession()
  const {
    data: questionsData,
    isLoading: isLoadingQuestions,
    status: statusQuestions,
  } = useQuestionAll()

  const {mutate, isLoading} = useSubmitAnswer(() =>
    conformModalRef?.current?.click(),
  )

  const {
    register,
    handleSubmit,
    trigger,
    formState: {errors},
    watch,
  } = useForm({
    resolver: yupResolver(validation),
    defaultValues: {
      participant_id: session,
      answers: questionsData?.map((question) => ({
        question_id: question.id,
        answer_id: '',
      })),
    },
    mode: 'all',
  })

  const [questionStep, setQuestionStep] = useState(0)

  const handleOnSubmit = (values) => {
    values.participant_id = session
    mutate(values)
  }

  const isLastQuestions = questionStep === questionsData?.length - 1

  const answers = watch('answers')

  const isAdsBannerExist =
    questionsData &&
    questionsData[questionStep] &&
    questionsData[questionStep].is_ads &&
    questionsData[questionStep].question_ads.length > 0

  const banners = isAdsBannerExist
    ? questionsData[questionStep].question_ads.map((a) => a.photo)
    : []

  return (
    <section className='pendaftaran'>
      <div className='container-fluid px-0'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='nav-top-inside'>
              <a href='/survey'>
                <i className='icon-chevron_left'></i>
              </a>
              <h2>Survey</h2>
            </div>

            <div className='form-survey'>
              <div className='form-wizard'>
                <form
                  className='mt-5'
                  onSubmit={handleSubmit(handleOnSubmit)}
                  ref={formRef}
                >
                  <StepWizard
                    questionsData={questionsData}
                    questionsAnwers={answers?.filter(
                      ({answer_id}) => !!answer_id,
                    )}
                  />
                  {questionsData?.map((questionData, questionIdx) => (
                    <div
                      className={clsx('box-questions', {
                        'd-block': questionStep === questionIdx,
                      })}
                      key={questionIdx}
                    >
                      <input
                        hidden
                        {...register(`answers.${questionIdx}.question_id`)}
                        value={questionData?.id}
                      />
                      <h2>{questionData?.question}</h2>
                      <div className='choose-option'>
                        {questionData?.survey_answers?.map((answer, i) => (
                          <Fragment key={i}>
                            <label
                              className={clsx('form-control', {
                                'is-invalid':
                                  !!errors.answers &&
                                  !!errors.answers[questionIdx]?.answer_id
                                    ?.message,
                              })}
                              role='button'
                            >
                              <input
                                type='radio'
                                name={questionData?.question}
                                {...register(
                                  `answers.${questionIdx}.answer_id`,
                                )}
                                value={answer.id}
                              />
                              {answer?.answer_text}
                            </label>
                            {questionData?.survey_answers?.length - 1 === i ? (
                              <div className='invalid-feedback'>
                                {errors?.answers &&
                                  errors.answers[questionIdx]?.answer_id
                                    ?.message}
                              </div>
                            ) : null}
                          </Fragment>
                        ))}
                      </div>
                      <div
                        className={clsx('form-wizard-buttons mt-5', {
                          'justify-content-end': questionStep === 0,
                        })}
                      >
                        {questionStep > 0 ? (
                          <button
                            type='button'
                            className='btn btn-previous'
                            onClick={() => setQuestionStep((prev) => prev - 1)}
                          >
                            <img src={grayBack} alt='' /> Kembali
                          </button>
                        ) : null}
                        {isLastQuestions ? (
                          <button
                            className='btn btn-primary btn-next'
                            type='button'
                            onClick={() =>
                              isAdsBannerExist
                                ? addsModalRef?.current?.click()
                                : conformModalRef?.current?.click()
                            }
                          >
                            Simpan
                          </button>
                        ) : (
                          <button
                            onClick={() =>
                              trigger(`answers.${questionIdx}.answer_id`).then(
                                (valid) => {
                                  if (valid && !isLastQuestions) {
                                    isAdsBannerExist
                                      ? addsModalRef?.current?.click()
                                      : setQuestionStep((prev) => prev + 1)
                                  }
                                },
                              )
                            }
                            type='button'
                            className={clsx('btn btn-primary btn-next', {
                              'w-50': questionStep === 0,
                            })}
                          >
                            Selanjutnya
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConformModal
        ref={conformModalRef}
        conformModalId={conformModalId}
        isLoading={isLoading}
        onConfirm={() => handleSubmit(handleOnSubmit)()}
      />
      <AddsModal
        key={questionStep}
        addsModalId={addsModalId}
        banners={typeof banners === 'string' ? [banners] : banners}
        ref={addsModalRef}
        onClose={() => {
          if (isLastQuestions) conformModalRef.current?.click()
          else setQuestionStep((prev) => prev + 1)
        }}
      />
    </section>
  )
}

export default Questions
