import Slider from 'react-slick'

export const SimilarProducts = ({products}) => {
  return (
    <Slider
      className='slick-similar-product'
      centerPadding='2px'
      centerMode
      draggable
      slidesToShow={2}
      autoplay={2000}
      arrows={false}
      dots
      adaptiveHeight={true}
    >
      {products?.map((product) => (
        <a key={product?.id} href={`/product/${product.id}`}>
          <img src={`${product.images[0].photo}`} alt='product' />
          <div className='caption-product'>
            <h2>{product?.name}</h2>
            <h6>{product?.short_note}</h6>
            <span>Lihat Detail</span>
          </div>
        </a>
      ))}
    </Slider>
  )
}
