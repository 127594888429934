import {morinagaLogo} from 'assetConstants'
import topSurveyIllust from 'assets/img/survey-berhasil-illust-top.svg'
import {Link} from 'react-router-dom'

const SuccessSurvey = () => {
  return (
    <section className='pendaftaran-berhasil'>
      <div className='container-fluid px-0'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='banner-top-daftar text-center '>
              <div className='logo-top'>
                <img src={morinagaLogo} alt='' />
              </div>
              <div className='illustration-top'>
                <img src={topSurveyIllust} alt='' />
              </div>
            </div>
            <div className='desc-text-box'>
              <h2>Selamat Anda Berhasil</h2>
              <p>
                Anda sudah berhasil mendaftar dan mengisi survey dari kami, Anda
                berhak untuk melakukan redeem hadiah.
              </p>
              <div className='btn-bottom-fixed'>
                <Link to='/redeem' className='btn btn-primary btn-next '>
                  Redeem Hadiah
                </Link>
                <Link to='/' className='btn btn-primary-outline btn-next mt-3'>
                  Kembali ke halaman awal
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SuccessSurvey
