import {useProductDetail} from 'hooks/query/useProduct'
import {useParams} from 'react-router-dom'
import Slider from 'react-slick'
import {SimilarProducts} from '../components/SimilarProducts'
import {Tags} from '../components/Tags'

const ProductDetail = () => {
  const {id} = useParams()
  const {data, isLoading} = useProductDetail(id)
  return (
    <section id='content-body'>
      <section className='product-detail'>
        <div className='container-fluid px-0'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='nav-top-inside'>
                <a href='/product'>
                  <i className='icon-chevron_left'></i>
                </a>
                <h2>Detail Produk</h2>
              </div>
              <Slider
                className='img-product-detail'
                centerMode
                draggable
                slidesToShow={1}
                autoplay={2000}
                arrows={false}
                adaptiveHeight={true}
              >
                {data?.images?.map((image, imageIdx) => (
                  <img key={imageIdx} src={image.photo} alt='Product Img' />
                ))}
              </Slider>
              <div className='detail-info'>
                <div className='container-fluid'>
                  <h2>{data?.name}</h2>
                  <Tags tags={data?.tags} />
                  <div className='accordion' id='accordionExample'>
                    <div className='accordion-item'>
                      <h2 className='accordion-header' id='headingOne'>
                        <button
                          className='accordion-button'
                          type='button'
                          data-bs-toggle='collapse'
                          data-bs-target='#collapseOne'
                          aria-expanded='true'
                          aria-controls='collapseOne'
                        >
                          Deskripsi Produk
                        </button>
                      </h2>
                      <div
                        id='collapseOne'
                        className='accordion-collapse collapse show'
                        aria-labelledby='headingOne'
                        data-bs-parent='#accordionExample'
                      >
                        <div
                          className='accordion-body'
                          dangerouslySetInnerHTML={{__html: data?.description}}
                        ></div>
                      </div>
                    </div>
                    {/* <div className='accordion-item'>
                      <h2 className='accordion-header' id='headingTwo'>
                        <button
                          className='accordion-button collapsed'
                          type='button'
                          data-bs-toggle='collapse'
                          data-bs-target='#collapseTwo'
                          aria-expanded='false'
                          aria-controls='collapseTwo'
                        >
                          Informasi Nutrisi
                        </button>
                      </h2>
                      <div
                        id='collapseTwo'
                        className='accordion-collapse collapse'
                        aria-labelledby='headingTwo'
                        data-bs-parent='#accordionExample'
                      >
                        <div
                          className='accordion-body'
                          dangerouslySetInnerHTML={{__html: data?.nutrition}}
                        ></div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='similar-product'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12'>
              <h4>Produk Serupa</h4>
              <SimilarProducts products={data?.similar_products} />
            </div>
          </div>
        </div>
      </section>
    </section>
  )
}

export default ProductDetail
