import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import 'assets/css/fonts.css'
import 'assets/css/general.css'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import React from 'react'
import {CookiesProvider} from 'react-cookie'
import ReactDOM from 'react-dom/client'
import {Toaster} from 'react-hot-toast'
import {RouterProvider, createBrowserRouter} from 'react-router-dom'
import {setupInterceptors} from 'service/core'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import reportWebVitals from './reportWebVitals'
import routes from './routes'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

const client = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5000,
      refetchInterval: false,
      retry: 1,
      refetchOnWindowFocus: false,
    },
  },
})

const router = createBrowserRouter(routes)

setupInterceptors()

dayjs.extend(advancedFormat)

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Toaster />
    <CookiesProvider
      defaultSetOptions={{
        path: '/',
        secure: process.env.NODE_ENV === 'production',
        sameSite: 'lax',
      }}
    >
      <QueryClientProvider client={client}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </CookiesProvider>
  </React.StrictMode>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
