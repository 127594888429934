import {morinagaLogo} from 'assetConstants'
import icFb from 'assets/img/ic-facebook.svg'
import icInsta from 'assets/img/ic-instagram.svg'
import icMail from 'assets/img/ic-mail.svg'
import icPhone from 'assets/img/ic-phone.svg'
import icTwit from 'assets/img/ic-twitter.svg'
import icWA from 'assets/img/ic-wa.svg'
import icYt from 'assets/img/ic-youtube.svg'

const Footer = () => {
  return (
    <footer>
      <div className='container-fluid'>
        <a href='/' className='d-block mb-3'>
          <img alt='' src={morinagaLogo} width='45px' />
        </a>
        <p className='mb-3'>
          PT. Sanghiang Perkasa (Kalbe Nutritionals) Altira Business Park Lt. 21
          Jl. Yos Sudarso Kavling 85 - Jakarta Utara, Jakarta 14350
        </p>
        <div className='d-flex row'>
          <div className='col-md-7'>
            <p>
              <img alt='' src={icPhone} /> (+62) 817 588 830
            </p>
            <p>
              <img alt='' src={icWA} /> (+62) 8001 402000
            </p>
            <p>
              <img alt='' src={icMail} /> customer@kalbenutritionals.com
            </p>
          </div>
          <div className='col-md-5'>
            <p>Ikuti Kami</p>
            <p>
              <a href='https://www.youtube.com/@MorinagaPlatinum'>
                <img alt='' src={icYt} />
              </a>
              <a href='https://twitter.com/MorinagaID'>
                <img alt='' src={icTwit} />
              </a>
              <a href='https://www.facebook.com/morinagaplatinum/'>
                <img alt='' src={icFb} />
              </a>
              <a href='https://www.instagram.com/morinagaplatinum'>
                <img alt='' src={icInsta} />
              </a>
            </p>
            <p>
              <a href='/'>Syarat & Ketentuan</a>
            </p>
          </div>
        </div>
      </div>
      <div className='copyright'>
        Hak Cipta © 2023{' '}
        <a
          href='https://morinagaplatinum.com/'
          className='copyright'
          style={{textDecoration: 'none', padding: 'none'}}
        >
          Morinaga
        </a>
      </div>
    </footer>
  )
}

export default Footer
