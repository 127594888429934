import {useQuery} from '@tanstack/react-query'
import useSession from 'hooks/useSession'
import api from 'service/core'

export const useCandidates = (search, callback) =>
  useQuery(['candidates-search', search], () =>
    api
      .get(`pub/candidate/lists?page=0&search=${search}&size=10`)
      .then((response) => {
        callback(response?.data?.data?.data ?? [])
        return response?.data?.data
      })
      .catch((error) => {
        callback([])
        return error
      }),
  )

export const useDetailUser = () => {
  const {session} = useSession()
  return useQuery(
    ['user-detail', session],
    () =>
      api
        .get(`/pub/participant/${session}/detail`)
        .then((response) => {
          return response?.data?.data
        })
        .catch(() => {
          return null
        }),
    {
      enabled: !!session,
    },
  )
}
