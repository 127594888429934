import Loader from 'components/Loader'
import {useRedeem} from 'hooks/query/useSurvey'
import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

const Redeem = () => {
  const {mutateAsync, isLoading, status} = useRedeem()
  const [hasMutated, setHasMutated] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    // Call the mutation only if it hasn't been called yet and is not in progress
    if (!hasMutated && status !== 'loading') {
      setHasMutated(true) // Set the flag to prevent further calls

      mutateAsync()
        .then(() => {
          navigate('/redeem/success')
        })
        .catch((err) => {
          navigate('/redeem/error/', {state: err?.message})
        })
    }
  }, [mutateAsync, status, hasMutated, navigate])
  return <Loader loading={isLoading} />
}

export default Redeem
