import {icCalendar, icClock} from 'assetConstants'
import icPin from 'assets/img/ic-pin.svg'
import dayjs from 'dayjs'
import {useSymposiumScheduleDetail} from 'hooks/query/useSymposiumSchedule'
import {useNavigate, useParams} from 'react-router-dom'

const SymposiumScheduleDetail = () => {
  const {id} = useParams()
  const {data, isLoading} = useSymposiumScheduleDetail(id)
  const navigate = useNavigate()

  return (
    <section class='detail-symposium'>
      <div class='container-fluid px-0'>
        <div class='row'>
          <div class='col-md-12'>
            <div class='nav-top-inside'>
              <span onClick={() => navigate(-1)}>
                <i class='icon-chevron_left'></i>
              </span>
              <h2>Detail Symposium </h2>
            </div>
            <div class='detail-symposium'>
              <img src={data?.photo} alt='' />
              <div class='caption-symposium'>
                <h2>{data?.title}</h2>

                <p class='d-flex align-items-center mb-3'>
                  <img src={icPin} alt='' /> {data?.location}
                </p>
                <div class='d-flex'>
                  <p class='d-flex align-items-center'>
                    <img src={icCalendar} alt='' />{' '}
                    {dayjs(data?.event_date).format('dddd, MMM Do YYYY')}
                  </p>
                  <p class='d-flex align-items-center'>
                    <img src={icClock} alt='' /> {data?.event_time_start} -{' '}
                    {data?.event_time_end}
                  </p>
                </div>

                <div
                  class='desc-symposium'
                  dangerouslySetInnerHTML={{__html: data?.description}}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SymposiumScheduleDetail
