import menuProduct from 'assets/img/menu-produk.png'
import menuSymposium from 'assets/img/menu-symposium.svg'

const MenuSection = () => {
  return (
    <section className='our-menu'>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-12'>
            <h4>Eksplorasi </h4>
            <p>
              Cari tahu lebih jauh mengenai jadwal symposium dan produk kami
            </p>
            <ul>
              <li>
                <a href='/product'>
                  <img src={menuProduct} alt='menu product' />
                  <h4>
                    Produk <br />
                    Morinaga
                  </h4>
                </a>
              </li>
              <li>
                <a href='/symposium-schedule'>
                  <img src={menuSymposium} alt='menu symposium' />
                  <h4>
                    Jadwal <br />
                    Symposium
                  </h4>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MenuSection
