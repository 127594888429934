import {useProductBannerLists} from 'hooks/query/useProductBanner'
import Slider from 'react-slick'

const SlideSection = () => {
  const {data} = useProductBannerLists()
  return (
    <section className='slide-banner'>
      <div className='container-fluid px-0'>
        <div className='row'>
          <div className='col-md-12'>
            <Slider
              className='slick-banner-product'
              centerPadding='20px'
              centerMode
              draggable
              slidesToShow={1}
              autoplay={2000}
              arrows={false}
              dots
              adaptiveHeight={true}
            >
              {data?.map((data) => {
                return (
                  <a key={data.id} href={data?.image}>
                    <img src={data?.image} alt='Product Img' key={data?.id} />
                  </a>
                )
              })}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SlideSection
