import dayjs from 'dayjs'
import useQueryParams from 'hooks/useQueryParam'
import useSession from 'hooks/useSession'
import {useNavigate} from 'react-router-dom'
import QrcodePlugin from '../plugins/QrcodePlugin'

const Scan = () => {
  const {setCookie} = useSession()
  const navigate = useNavigate()
  const query = useQueryParams()

  const to = query.get('to')

  const saveSalesId = (value) => {
    setCookie('sales_promotion_id', value, {
      expires: dayjs().add(1, 'month').toDate(),
    })
  }

  const onNewScanResult = (result) => {
    const arrayResult = result?.split('/')
    const salesId = arrayResult[3]
    if (salesId) {
      saveSalesId(salesId)
      navigate(to ? `/${to}` : '/', {
        replace: true,
      })
    }
  }

  return <QrcodePlugin onSuccessScan={onNewScanResult} />
}

export default Scan
