export const Tags = ({tags}) => {
  return (
    <div className='badge-list'>
      {tags?.map((tag) => (
        <span key={tag?.id} className='badge-yellow'>
          {tag.name}
        </span>
      ))}
    </div>
  )
}
