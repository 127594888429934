import {morinagaLogo} from 'assetConstants'
import Footer from 'components/Footer'
import {useHome} from 'hooks/query/useHome'
import MenuSection from './components/MenuSection'
import RegisterSection from './components/RegisterSection'
import SlideSection from './components/SlideSection'
import TermModal from './components/TermModal'

const Home = () => {
  const data = useHome()
  return (
    <>
      <section className='home-top-banner'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='d-flex nav-top'>
                <a href='/'>
                  <img src={morinagaLogo} alt='loggo morinaga' />
                </a>
              </div>
              <div className='content-banner'>
                <img src={data?.data?.welcome_banner} alt='Welcome Banner' />
                <h2>
                  {data?.data?.welcome_text}
                  <br />
                  <span>{data?.data?.welcome_date}</span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <SlideSection />
      <MenuSection />
      <RegisterSection />
      <Footer />
      <TermModal />
    </>
  )
}

export default Home
