import {useQuery} from '@tanstack/react-query'
import api from 'service/core'

export const useCategoryAll = () =>
  useQuery(
    ['category-all'],
    () =>
      new Promise((resolve, reject) =>
        api
          .get('pub/category/all')
          .then((response) => resolve(response?.data?.data))
          .catch((error) => reject(error)),
      ),
  )
