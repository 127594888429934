export const Category = ({cat, setCategory, setSubCategory}) => {
  const handleClick = () => {
    setCategory(cat)
    setSubCategory(undefined)
  }
  return (
    <li
      draggable='true'
      className='nav-item'
      role='presentation'
      onClick={handleClick}
    >
      <button
        className='nav-link '
        id='year-tab-1'
        data-bs-toggle='tab'
        data-bs-target='#year-1'
        type='button'
        role='tab'
        aria-selected='true'
      >
        {cat?.name}
      </button>
    </li>
  )
}
