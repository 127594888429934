const StepWizard = ({questionsData, questionsAnwers = []}) => {
  const questionsLength = questionsData?.length ?? 0
  const answersLength = questionsAnwers.length ?? 0
  const percentage = Math.floor((answersLength * 100) / questionsLength)

  return (
    <>
      <div className='form-wizard-steps form-wizard-tolal-steps-4 d-flex d-flex justify-content-center align-items-center py-2'>
        <div className='form-wizard-progress'>
          <div
            className='form-wizard-progress-line'
            data-now-value={answersLength}
            data-number-of-steps={questionsLength}
            style={{width: `${percentage}%`}}
          ></div>
        </div>
        <div className='form-wizard-step active text-center'>
          <p>
            <span className='value-progress'>
              {isNaN(percentage) ? 0 : percentage}%
            </span>
            mengisi survey data
          </p>
          <p>{answersLength} Survey terjawab</p>
        </div>
      </div>
    </>
  )
}

export default StepWizard
