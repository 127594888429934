import SymposiumItem from 'components/Symposium/Item'
import {useSymposiumScheduleAll} from 'hooks/query/useSymposiumSchedule'
import Slider from 'react-slick'

const SlideSection = () => {
  const {data} = useSymposiumScheduleAll()

  return (
    <section className='slide-banner'>
      <div className='container-fluid px-0'>
        <div className='row'>
          <div className='col-md-12'>
            <Slider
              className='slick-banner-symposium'
              centerPadding='20px'
              centerMode
              draggable
              slidesToShow={1}
              autoplay={2000}
              arrows={false}
              dots
            >
              {data?.map((data) => (
                <SymposiumItem key={data?.id} {...data} />
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SlideSection
