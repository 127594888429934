export const SubCategory = ({
  subCategory,
  setSubCategory,
  handleActive,
  active,
}) => {
  const handleClick = () => {
    setSubCategory(subCategory)
    handleActive(subCategory)
  }

  return (
    <li
      className={active?.id === subCategory?.id ? 'tab-active' : ''}
      onClick={handleClick}
    >
      <a href='#tab-1' rel='nofollow'>
        {subCategory?.name}
      </a>
    </li>
  )
}
