import clsx from 'clsx'
import {forwardRef} from 'react'

/**
 *
 * @param {{children:import("react").ReactNode, modalId:string, size?:string}} param0
 * @returns
 */
const Modal = ({children, modalId, size = '', className = ''}) => {
  return (
    <div
      className={clsx('modal fade', className)}
      id={modalId}
      tabIndex='-1'
      aria-labelledby={`${modalId}Label`}
      aria-hidden='true'
    >
      <div
        className={
          'modal-dialog px-1 modal-dialog-centered modal-dialog-scrollable ' +
          size
        }
      >
        <div className='modal-content'>{children}</div>
      </div>
    </div>
  )
}

export default Modal

/**
 * props {text:string, targetModal:string, className:string}
 * @param {{text:string, targetModal:string, className:string}} props
 * @param {import('react').ForwardedRef} ref
 * @returns
 */
export const ModalButton = forwardRef(({text, targetModal, className}, ref) => (
  <button
    ref={ref}
    type='button'
    className={className}
    data-bs-toggle='modal'
    data-bs-target={`#${targetModal}`}
  >
    {text}
  </button>
))
