import Loader from 'components/Loader'
import dayjs from 'dayjs'
import useSession from 'hooks/useSession'
import {useCallback, useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'

const getPayload = async (token) => {
  const data = await new Promise((resolve, reject) => {
    try {
      const stringPayload = atob(token)
      const parsedData = JSON.parse(stringPayload)
      if (parsedData.salesId)
        setTimeout(() => {
          resolve(parsedData)
        }, 500)
    } catch (error) {
      reject(error)
    }
  })
  return data
}

const SalesHandler = () => {
  const navigate = useNavigate()
  const {setSession, setCookie} = useSession()
  const {action, token} = useParams()
  const [loading, setLoading] = useState(true)

  const getToken = useCallback(async () => {
    const payload = await getPayload(token)
    setSession(payload.participantId)
    setCookie('sales_promotion_id', payload.salesId, {
      expires: dayjs().add(1, 'month').toDate(),
    })
    setLoading(false)
  }, [setCookie, setSession, token])

  useEffect(() => {
    getToken()

    const to = setTimeout(() => {
      navigate('/' + action)
    }, 500)

    return () => {
      clearTimeout(to)
    }
  }, [action, getToken, navigate])

  return <Loader loading={loading} />
}

export default SalesHandler
