import {morinagaLogo} from 'assetConstants'
import nextIc from 'assets/img/ic-next-white.svg'
import illusTopSurvey from 'assets/img/survey-illust-top.svg'
import {Link} from 'react-router-dom'

const Survey = () => {
  return (
    <section className='pendaftaran-berhasil'>
      <div className='container-fluid px-0'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='banner-top-daftar text-center '>
              <div className='logo-top'>
                <img src={morinagaLogo} alt='' />
              </div>
              <div className='illustration-top'>
                <img src={illusTopSurvey} alt='' />
              </div>
            </div>
            <div className='desc-text-box'>
              <h2>Bantu kami mengisi Survey</h2>
              <p style={{fontSize: 14}}>
                Kami menghargai partisipasi Anda dalam mengisi data ini. Data
                yang Anda berikan akan digunakan untuk meningkatkan layanan
                kami.
              </p>
              <p style={{fontSize: 14}}>
                Silakan isi data survey dengan lengkap
              </p>

              <div className='btn-bottom-fixed'>
                <Link
                  to='/survey/questions'
                  className='btn btn-primary btn-next '
                >
                  Mulai <img src={nextIc} alt='' />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Survey
