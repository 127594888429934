import surveBerhasilIc from 'assets/img/ic-survey-berhasil.svg'
import Modal, {ModalButton} from 'components/Modal'
import {forwardRef} from 'react'

const ConformModal = forwardRef(
  ({isLoading, onConfirm, conformModalId}, ref) => {
    return (
      <>
        <Modal modalId={conformModalId}>
          <div className='modal-body'>
            <div className='text-center'>
              <img src={surveBerhasilIc} alt='' />
            </div>
            <h2 className='text-center'>Simpan Survey Data ?</h2>
            <p className='text-center'>
              Apakah Anda yakin untuk menyimpan survey data yang sudah Anda isi?
            </p>
          </div>
          <div className='modal-footer justify-content-between border-0'>
            <button
              type='button'
              className='btn btn-primary-outline'
              data-bs-dismiss='modal'
            >
              Batal
            </button>
            <button
              onClick={onConfirm}
              className='btn btn-primary'
              disabled={isLoading}
            >
              {isLoading ? 'Loading...' : 'Simpan'}
            </button>
          </div>
        </Modal>
        <ModalButton
          className='d-none'
          ref={ref}
          targetModal={conformModalId}
        />
      </>
    )
  },
)

ConformModal.displayName = 'ConformModal'
export default ConformModal
