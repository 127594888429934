import {yupResolver} from '@hookform/resolvers/yup'
import {useMutation, useQuery} from '@tanstack/react-query'
import cx from 'clsx'
import {useCandidates} from 'hooks/query/useCandidate'
import {useClickOutside} from 'hooks/useClickOutside'
import useDebounce from 'hooks/useDebounce'
import useDisclosure from 'hooks/useDisclosure'
import useSession from 'hooks/useSession'
import useToaster from 'hooks/useToaster'
import {useRef, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useNavigate} from 'react-router-dom'
import {ReactSearchAutocomplete} from 'react-search-autocomplete'
import Select from 'react-select'
import api from 'service/core'
import * as Yup from 'yup'
import {phoneRegex} from '../../constants'
import SpecialistOption from './components/SpecialistOption'

const validation = Yup.object({
  name: Yup.string()
    .required('Nama Lengkap harus diisi')
    .min(3, 'Full Name must be at least 3 characters'),
  specialization: Yup.string().required('Spesialisasi harus diisi'),
  city_id: Yup.string().required('Kota Asal harus diisi'),
  province: Yup.string().required('Wilayah/Regional harus diisi'),
  office_address: Yup.string().required('Tempat Praktek harus diisi'),
  phone: Yup.string()
    .required('No. Whatsapp harus diisi')
    .test('validatephone', 'No. Whatsapp tidak valid', (value) =>
      phoneRegex.exec(value),
    ),
  sales_promotion_id: Yup.string(),
  other_specialization: Yup.string().test(
    'is-required',
    'Wajib diisi',
    function (value, ctx) {
      const {specialization} = ctx.parent
      if (specialization === 'LAINNYA') return !!value
      return true
    },
  ),
})

const RegisterPage = () => {
  const optionRef = useRef()
  const {isOpen, onClose, onOpen} = useDisclosure()
  const toaster = useToaster()
  const navigate = useNavigate()
  const {sales_promotion_id} = useSession()
  useClickOutside(optionRef, onClose)
  const [items, setItems] = useState([])
  const [search, setSearch] = useState('')
  const debounsceSearch = useDebounce(search, 500)
  const {setSession} = useSession()
  const [selectedProvince, setSelectedProvince] = useState(null)

  useCandidates(debounsceSearch, setItems)

  const {isLoading, mutate: registerUser} = useMutation(
    (json) =>
      toaster(
        new Promise(async (resolve, reject) => {
          try {
            await api
              .post('/pub/participant/save', json)
              .then((response) => resolve(response?.data?.data))
          } catch (error) {
            reject(error?.response?.data)
          }
        }),
        'Registrasi berhasil',
        'Loading...',
      ),
    {
      onSuccess: (data) => {
        setSession(data)
        navigate('/register-success')
      },
    },
  )

  const {
    handleSubmit,
    formState: {errors},
    control,
    watch,
    setValue,
    register,
  } = useForm({
    resolver: yupResolver(validation),
    defaultValues: {
      name: '',
      specialization: '',
      city_id: '',
      phone: '',
      office_address: '',
      province: '',
      sales_promotion_id,
    },
    mode: 'all',
  })

  const formatResult = (item) => {
    return (
      <div className='d-flex'>
        <span style={{display: 'block', minWidth: 100}}>{item.name}</span>
        <span style={{display: 'block'}} className='mx-3'>
          -
        </span>
        <span style={{display: 'block', minWidth: 100}}>{item.phone}</span>
      </div>
    )
  }

  const handleOnsubmit = async (values) => {
    console.info(values)
    registerUser(values)
  }

  const watchspecialization = watch('specialization')

  const isOtherSpecialization = watchspecialization === 'LAINNYA'

  const handleSpecialization = (input) => {
    setValue('specialization', input.value)
    onClose()
  }

  const {data: dataProvinces} = useQuery(
    ['province'],
    () =>
      new Promise((resolve) => {
        api
          .get('/pub/location/provinces')
          .then((response) => {
            resolve(
              response?.data?.data?.map((v) => ({
                label: v?.name,
                value: v?.id,
              })),
            )
          })
          .catch(() => resolve([]))
      }),
  )

  const {data: dataCities} = useQuery(
    ['cities', selectedProvince?.value],
    () =>
      new Promise((resolve) => {
        api
          .get(`/pub/location/${selectedProvince?.value}/regencies`)
          .then((response) => {
            resolve(
              response?.data?.data?.map((v) => ({
                label: v?.name,
                value: v?.id,
              })),
            )
          })
          .catch(() => resolve([]))
      }),
    {
      enabled: !!selectedProvince,
    },
  )

  return (
    <section className='pendaftaran'>
      <div className='container-fluid px-0'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='nav-top-inside'>
              <a href='/'>
                <i className='icon-chevron_left'></i>
              </a>
              <h2>Pendaftaran</h2>
            </div>

            <div className='form-pendaftaran'>
              <p>
                Silahkan isi data lengkap berikut ini untuk menyelesaikan proses
                pendaftaran Anda
              </p>
              <form onSubmit={handleSubmit(handleOnsubmit)}>
                <div className='form-group'>
                  <label>
                    Nama Lengkap<span className='mandatory'>*</span>
                  </label>
                  <div className='input-group mb-3'>
                    <span className='input-group-text' id='basic-addon1'>
                      <i className='icon-user-01'></i>
                    </span>
                    <Controller
                      name='name'
                      control={control}
                      render={({field}) => (
                        <>
                          <ReactSearchAutocomplete
                            // className='form-control relative p-0'
                            showIcon={false}
                            styling={{
                              boxShadow: 'none',
                              border: 'none',
                              backgroundColor: 'transparent',
                              clearIconMargin: '',
                              zIndex: 5,
                            }}
                            showClear={false}
                            items={items}
                            formatResult={formatResult}
                            showItemsOnFocus
                            onSelect={({name}) => {
                              field.onChange(name)
                            }}
                            onSearch={(input) => {
                              field.onChange(input)
                              setSearch(input)
                            }}
                            className={cx(
                              'form-control relative p-0 rounded-end',
                              {
                                'is-invalid': !!errors.name,
                              },
                            )}
                            {...register('name')}
                          />
                          <div className='invalid-feedback'>
                            {errors.name?.message}
                          </div>
                        </>
                      )}
                    />
                  </div>
                </div>
                <div className={'form-group mb-3'}>
                  <label>
                    Spesialisasi<span className='mandatory'>*</span>
                  </label>
                  <div
                    className='input-group'
                    onClick={() => (isOpen ? onClose() : onOpen())}
                  >
                    <span className='input-group-text' id='basic-addon1'>
                      <i className='icon-award-02'></i>
                    </span>
                    <input
                      type='text'
                      className='form-control form-select select-spesialisasi'
                      placeholder='Pilih Spesialisasi '
                      aria-label='Username'
                      aria-describedby='basic-addon1'
                      value={watchspecialization}
                      readOnly
                    />
                  </div>
                </div>
                {isOtherSpecialization ? (
                  <div className='form-group mb-3'>
                    <input
                      className={cx('form-control', {
                        'is-invalid': !!errors.other_specialization,
                      })}
                      placeholder='Jelaskan spesialisasi anda'
                      {...register('other_specialization')}
                    />
                    <div className='invalid-feedback'>
                      {errors.other_specialization?.message}
                    </div>
                  </div>
                ) : null}
                <div className='form-group'>
                  <label>
                    Wilayah/Regional<span className='mandatory'>*</span>
                  </label>
                  <div className='input-group mb-3 has-validation'>
                    <span className='input-group-text' id='basic-addon1'>
                      <i className='icon-captive_portal'></i>
                    </span>
                    <Controller
                      control={control}
                      name='province'
                      render={({field: {onChange}}) => (
                        <Select
                          className={cx('form-control', {
                            'is-invalid': !!errors.province,
                          })}
                          value={selectedProvince}
                          options={dataProvinces}
                          onChange={(newValue) => {
                            if (newValue.label) {
                              onChange(newValue.label)
                              setSelectedProvince(newValue)
                            }
                          }}
                          styles={{
                            control: (base) => ({
                              ...base,
                              border: 0,
                              boxShadow: 0,
                            }),
                          }}
                        />
                      )}
                    />
                    <div className='invalid-feedback'>
                      {errors.province?.message}
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <label>
                    Kota Asal<span className='mandatory'>*</span>
                  </label>
                  <div className='input-group mb-3 has-validation'>
                    <span className='input-group-text' id='basic-addon1'>
                      <i className='icon-garage_home'></i>
                    </span>
                    <Controller
                      control={control}
                      name='city_id'
                      render={({field: {onChange, value}}) => (
                        <Select
                          className={cx('form-control', {
                            'is-invalid': !!errors.city_id,
                          })}
                          value={
                            dataCities?.find((op) => op.value === value) ?? {}
                          }
                          options={dataCities}
                          onChange={(newValue) => {
                            if (newValue.value) {
                              onChange(newValue.value)
                            }
                          }}
                          styles={{
                            control: (base) => ({
                              ...base,
                              border: 0,
                              boxShadow: 0,
                            }),
                          }}
                        />
                      )}
                    />
                    <div className='invalid-feedback'>
                      {errors.city_id?.message}
                    </div>
                  </div>
                </div>

                <div className='form-group'>
                  <label>
                    Tempat Praktek<span className='mandatory'>*</span>
                  </label>
                  <div className='input-group mb-3 has-validation'>
                    <span className='input-group-text' id='basic-addon1'>
                      <i className='icon-domain'></i>
                    </span>
                    <input
                      type='text'
                      placeholder='Masukan nama tempat praktek Anda '
                      aria-label='Username'
                      aria-describedby='basic-addon1'
                      className={cx('form-control', {
                        'is-invalid': !!errors.office_address,
                      })}
                      {...register('office_address')}
                    />
                    <div className='invalid-feedback'>
                      {errors.office_address?.message}
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <label>
                    No. Whatsapp<span className='mandatory'>*</span>
                  </label>
                  <div className='input-group mb-3 has-validation'>
                    <span className='input-group-text' id='basic-addon1'>
                      <i className='icon-ad_units'></i>
                    </span>
                    <input
                      type='text'
                      placeholder='Masukan nomor whatsapp business Anda'
                      aria-label='Username'
                      aria-describedby='basic-addon1'
                      className={cx('form-control', {
                        'is-invalid': !!errors.phone,
                      })}
                      {...register('phone')}
                    />
                    <div className='invalid-feedback'>
                      {errors.phone?.message}
                    </div>
                  </div>
                  <p>
                    Kami memerlukan nomor whatsapp untuk verifikasi pendaftaran
                    Anda
                  </p>
                </div>
                <button
                  type='submit'
                  className='btn btn-primary border-0 w-100'
                  style={{zIndex: 3}}
                >
                  {isLoading ? 'Loading...' : 'Daftar'}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <SpecialistOption
        isOpen={isOpen}
        ref={optionRef}
        onChoose={handleSpecialization}
      />
    </section>
  )
}

export default RegisterPage
