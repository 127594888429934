import {morinagaLogoRed} from 'assetConstants'
import klaim from 'assets/img/illust-klaim.png'
import clsx from 'clsx'
import Modal, {ModalButton} from 'components/Modal'
import {useDetailUser} from 'hooks/query/useCandidate'
import useSession from 'hooks/useSession'
import {useRef} from 'react'
import {Link} from 'react-router-dom'

const noSalesModal = 'noSalesModal'

const RegisterSection = () => {
  const modalBtnRef = useRef()
  const {sales_promotion_id} = useSession()
  const {data} = useDetailUser()
  const isSurveyDone = data?.is_survey

  const renderButton = () => {
    if (isSurveyDone) {
      return (
        <Link to='/redeem' className='btn btn-primary'>
          Redeem Merchandise disini
        </Link>
      )
    }

    return sales_promotion_id ? (
      <a href='/register' className='btn btn-primary'>
        Daftar Sekarang
      </a>
    ) : (
      <button
        onClick={() => modalBtnRef?.current?.click()}
        className='btn btn-primary'
      >
        Daftar Sekarang
      </button>
    )
  }
  return (
    <section
      className={clsx('register-klaim', {'klaim-berhasil': isSurveyDone})}
    >
      <div className='container-fluid'>
        <div className='banner-register'>
          <img src={klaim} alt='Klaim ilustration' />
          <div>
            {/* <h4>
              {isSurveyDone
                ? 'Selamat Anda berhak mendapatkan merchandise menarik dari kami'
                : 'Dapatkan Merchandise menarik dengan registrasi disini'}
            </h4> */}
            {isSurveyDone ? (
              <h4>
                Selamat Anda berhak mendapatkan merchandise menarik dari kami
              </h4>
            ) : (
              <>
                <h5
                  style={{fontSize: '14px', fontWeight: '700'}}
                  className='text-secondary'
                >
                  Dapatkan
                  <h4
                    style={{display: 'inline', fontSize: '16px'}}
                    className='text-danger'
                  >
                    {' '}
                    Exclusive ToteBag Morinaga
                  </h4>
                </h5>
                <h5
                  className='text-secondary'
                  style={{fontSize: '12px', fontWeight: '500'}}
                >
                  dengan{' '}
                  <h4 style={{display: 'inline'}} className='text-muted'>
                    mendatangi Booth Morinaga
                  </h4>{' '}
                  dan mengisi{' '}
                  <h4 style={{display: 'inline'}} className='text-muted'>
                    Form Registrasi
                  </h4>
                </h5>
              </>
            )}
            {renderButton()}
          </div>
        </div>
      </div>
      <Modal modalId={noSalesModal}>
        <div className='modal-body text-center'>
          <img src={morinagaLogoRed} alt='' />
          <p className='mb-3'>Anda belum terhubung dengan merchan</p>
          <p>
            <strong>Scan qrcode yang disediakan oleh merchan?</strong>
          </p>
        </div>
        <div className='modal-footer'>
          <button
            type='button'
            className='btn btn-dark'
            onClick={() => modalBtnRef?.current?.click()}
          >
            Tidak
          </button>
          <Link
            to='/scan/?to=register'
            onClick={() => modalBtnRef?.current?.click()}
            className='btn btn-primary'
          >
            Ya
          </Link>
        </div>
      </Modal>
      <ModalButton
        ref={modalBtnRef}
        targetModal={noSalesModal}
        className='d-none'
      />
    </section>
  )
}

export default RegisterSection
