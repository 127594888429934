import {icCalendar, icClock} from 'assetConstants'
import dayjs from 'dayjs'

const SymposiumItem = ({
  id,
  slider_photo,
  photo,
  title,
  event_date,
  event_time_start,
  event_time_end,
}) => {
  return (
    <a href={`/symposium-schedule/${id}`}>
      <img src={slider_photo} alt='' />
      <div className='caption-symposium'>
        <h2>{title}</h2>
        <div className='d-flex'>
          <p className='d-flex align-items-center'>
            <img alt='calendar' src={icCalendar} />{' '}
            {dayjs(event_date).format('dddd, MMM Do YYYY')}
          </p>
          <p className='d-flex align-items-center'>
            <img src={icClock} alt='clock' /> {event_time_start} -{' '}
            {event_time_end}
          </p>
        </div>
      </div>
    </a>
  )
}

export default SymposiumItem
