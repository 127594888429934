import {morinagaLogo} from 'assetConstants'
import illusTop from 'assets/img/daftar-illust-top.svg'

const RegisterSucess = () => {
  return (
    <section className='pendaftaran-berhasil'>
      <div className='container-fluid px-0'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='banner-top-daftar text-center '>
              <div className='logo-top'>
                <img src={morinagaLogo} alt='' />
              </div>
              <div className='illustration-top'>
                <img src={illusTop} alt='' />
              </div>
            </div>
            <div className='desc-text-box'>
              <h2>Pendaftaran berhasil</h2>
              <p>
                Proses pendaftaran sudah berhasil dilakukan, Anda harus segera
                melakukan verifikasi data, yang akan kami kirimkan ke nomor
                whatsapp yang anda berikan.{' '}
              </p>
              <p>
                Setelah anda melakukan verifikasi, anda akan kami arahkan untuk
                mengisi survey data{' '}
              </p>

              <div className='btn-bottom-fixed'>
                <a href='/survey' className='btn btn-primary btn-next '>
                  Isi Survey
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default RegisterSucess
